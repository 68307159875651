import React from 'react';
import { TabLink as NextNavLink } from '@/ui/shared/components/Next/Link';
import Button from '@/ui/shared/components/Button';
import SharedCustomers from '@/ui/shared/components/Landing/Sections/Customers';
import Section from '@/ui/shared/components/Landing/Sections/Section';
import SectionContainer from '@/ui/shared/components/Landing/Sections/SectionContainer';
import SectionHeader from '@/ui/shared/components/Landing/Sections/SectionHeader';
import customers from './constants';

import styles from './customers.scss';

const Customers = () => (
  <Section padding="top">
    <SectionContainer size="large">
      <SectionHeader
        title="Hundreds of leading corporations switched to DealVDR"
        description="The switch is easy. We understand your process and operate at a higher standard than legacy providers. "
        className={ styles.headerWrapper }
      />
      <SharedCustomers
        customers={ customers }
        showMoreButton={ (buttonProps) => (
          <NextNavLink
            to="/customers"
            data-test="exploreOurCustomers"
          >
            <Button
              { ...buttonProps }
              title="Explore Our Customers"
              dataTest="exploreOurCustomersButton"
            />
          </NextNavLink>
        ) }
      />
    </SectionContainer>
  </Section>
);

export default Customers;
