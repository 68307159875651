import { Icon, IconType } from '@dealroadshow/uikit';
import SectionWithImages from '@/ui/shared/components/Landing/Sections/Tabs/SectionWithImages';
import { ITabList } from '@/ui/shared/components/Landing/Sections/Tabs/interfaces';
import administrationImg from './assets/administration.webp';
import analyticsImg from './assets/analytics.webp';
import fileAccessImg from './assets/fileAccess.webp';
import securityAndComplianceImg from './assets/securityAndCompliance.webp';
import supportOneImg from './assets/supportOne.webp';
import supportTwoImg from './assets/supportTwo.webp';
import userManagementImg from './assets/userManagement.webp';

import styles from './features.scss';

const Feature = ({ text }: { text: string }) => (
  <div className={ styles.feature }>
    <div className={ styles.featureIcon }>
      <Icon type={ IconType.check } />
    </div>
    <div className={ styles.featureTitle }>{ text }</div>
  </div>
);

export const featuresTabsList: ITabList[] = [
  {
    key: 'administration',
    icon: IconType.folder,
    label: 'Administration',
    content: (
      <SectionWithImages
        images={ [
          {
            src: administrationImg,
            width: 710,
            height: 447,
            key: 1,
          },
        ] }
        caption="DealVDR Q&A’s list of open questions"
      >
        { [
          'Instantly provision new data rooms',
          'Upload via folder drag & drop',
          'Question and answer (Q&A) module',
          'Exportable file, user, and permission lists',
          'Upload via email',
          'Upload via mobile',
          'Upload via SFTP',
          'Upload via automated recorded telephone line',
          'Automatic additional storage provisioning',
        ].map((text) => (
          <Feature text={ text } key={ text } />
        )) }
      </SectionWithImages>
    ),
  },
  {
    key: 'analytics',
    icon: IconType.analytics,
    label: 'Analytics',
    content: (
      <SectionWithImages
        images={ [
          {
            src: analyticsImg,
            width: 710,
            height: 429,
            key: 2,
          },
        ] }
        caption="DealVDR account-level analytics"
      >
        { [
          'Analytics available in perpetuity',
          'Analytics exportable to Excel',
          'Automated analytics sent via email',
          'Track activity by Account',
          'Track activity by Contact',
          'Track activity by Document',
          'Track activity by Permission Group',
          'Immutable, detailed audit trails',
        ].map((text) => (
          <Feature text={ text } key={ text } />
        )) }
      </SectionWithImages>
    ),
  },
  {
    key: 'fileAccess',
    icon: IconType.fileLock,
    label: 'File Access',
    content: (
      <SectionWithImages
        images={ [
          {
            src: fileAccessImg,
            width: 710,
            height: 447,
            key: 3,
          },
        ] }
        caption="DealVDR file and folder management"
      >
        { [
          'Search by file, folder, type and index #',
          'Stage files and folders prior to sharing',
          'Bookmark and filter by new, unread and date',
          'Manage, download, and view only permissions',
          'Click to preview',
          'Dynamic watermarking',
          'Exportable file index',
          'Restrict or enable bulk download',
          'No plugins required',
        ].map((text) => (
          <Feature text={ text } key={ text } />
        )) }
      </SectionWithImages>
    ),
  },
  {
    key: 'securityAndCompliance',
    icon: IconType.document,
    label: 'Security & Compliance',
    content: (
      <SectionWithImages
        images={ [
          {
            src: securityAndComplianceImg,
            width: 710,
            height: 447,
            key: 4,
          },
        ] }
        caption="DealVDR immutable audit trail"
      >
        { [
          'Customizable disclaimer',
          'Immutable audit trails',
          'Two-factor authentication on data room level',
          'US-based hosting',
          'GDPR compliant; EU-US Data Privacy Framework certified',
          'ISO/IEC 27001:2013 certified data centers',
          '256-bit AES encryption at rest and transit',
          'SSAE16 & SOC 2 Type II certified annually',
          'Content delivery network with DDOS protection',
        ].map((text) => (
          <Feature text={ text } key={ text } />
        )) }
      </SectionWithImages>
    ),
  },
  {
    key: 'support',
    icon: IconType.operator,
    label: 'Support',
    content: (
      <SectionWithImages
        images={ [
          {
            src: supportOneImg,
            width: 268,
            height: 447,
            key: 5,
          },
          {
            src: supportTwoImg,
            width: 268,
            height: 447,
            key: 6,
          },
        ] }
        caption="DealVDR 24x7 live chat with our fanatical support"
      >
        { [
          'Unlimited 24x7x365 phone, email & live-chat support for all stakeholders',
          'Unlimited training for all stakeholders',
          '99.9% uptime guarantee',
          '100% NYC, London, and Hong Kong-based support',
          'Sub-10 minute response time to all emails ',
          'Encrypted USB archives available upon request',
          'Proactive follow-up of inactive invitees',
          '48-hour white glove data room migration',
        ].map((text) => (
          <Feature text={ text } key={ text } />
        )) }
      </SectionWithImages>
    ),
  },
  {
    key: 'userManagement',
    icon: IconType.users,
    label: 'User Management',
    content: (
      <SectionWithImages
        images={ [
          {
            src: userManagementImg,
            width: 710,
            height: 447,
            key: 7,
          },
        ] }
        caption="DealVDR user management"
      >
        { [
          'View data room as user',
          'User-specific audit trails',
          'Granular and stackable permissioning',
          'Bulk add users',
          'Stage users without access',
          'Add without notification',
          'Q&A-specific permissioning',
          'Customizable file upload email notifications',
          'Export user & permissions list to Excel',
        ].map((text) => (
          <Feature text={ text } key={ text } />
        )) }
      </SectionWithImages>
    ),
  },
];
