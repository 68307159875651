import React from 'react';
import BaseFeedbacks from '@/ui/shared/components/Landing/Sections/Feedbacks';
import SectionHeader from '@/ui/shared/components/Landing/Sections/SectionHeader';
import { feedbacksList } from '@/dataroom/ui/components/Landing/dealvdr/feedbacksList';
import Section from '@/ui/shared/components/Landing/Sections/Section';
import SectionContainer from '@/ui/shared/components/Landing/Sections/SectionContainer';

const Feedbacks = () => (
  <Section>
    <SectionContainer size="large">
      <SectionHeader
        title="Real, unsolicited feedback"
        description="We don't ask for testimonials. Below is unsolicited feedback we received in writing from clients."
      />
      <BaseFeedbacks
        cardStyle="modern"
        feedbacksList={ feedbacksList }
      />
    </SectionContainer>
  </Section>
);

export default Feedbacks;
