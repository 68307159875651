import React from 'react';
import cn from 'classnames';

import Animation from '@/ui/shared/components/Landing/Sections/Animation';
import styles from './intro.scss';

const params = {
  factor: 0.02,
  variation: 0.0017,
  amplitude: 300,
  lines: 24,
  waveColor: { r: 255, g: 255, b: 255, a: 0.1 },
  lineStroke: 1,
  speed: 0.0005,
};

interface IProps {
  className: string,
  video?: string,
  poster: string,
  description: React.ReactNode,
  title: React.ReactNode,
  actionButtons: React.ReactNode,
}

const Intro = ({ className, video, poster, description, title, actionButtons }: IProps) => (
  <div className={ cn(styles.intro, className) } data-test="introSection">
    <div className={ styles.introContainer }>
      <div className={ styles.introContent }>
        <h1 className={ styles.introTitle }>{ title }</h1>
        <div className={ styles.introDescription }>
          { description }
        </div>
        { actionButtons }
      </div>
      <div className={ styles.videoSection }>
        <div className={ styles.videoWrp }>
          <video autoPlay muted loop poster={ poster } style={ { width: '100%', height: '100%' } }>
            <source src={ video } />
          </video>
        </div>
      </div>
    </div>
    <Animation parameters={ params } />
    <div className={ styles.shadow } />
  </div>
);

export default Intro;
