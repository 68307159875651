import cn from 'classnames';
import Image from '@/ui/shared/components/Next/Image';
import { StaticImageData } from 'next/image';

import styles from './sectionWithImages.scss';

interface IProps {
  children: React.ReactNode,
  images: {
    src: StaticImageData,
    width: number,
    height: number,
    key: number,
  }[],
  caption: React.ReactNode,
  featuresClassName?: string,
}

const singleImage = (images) => (
  <div
    className={ styles.screenshotImageWrp }
    key={ images[0].key }
  >
    <Image
      src={ images[0].src }
      width={ images[0].width }
      height={ images[0].height }
      placeholder="empty"
      className={ styles.screenshot }
      alt="Feature image"
    />
  </div>
);

const multipleImage = (images) => (
  <div className={ styles.multipleImagesWrp }>
    {
      images.map((img) => (
        <div
          className={ styles.multipleScreenshotWrp }
          key={ img.key }
        >
          <Image
            src={ img.src }
            width={ img.width }
            height={ img.height }
            placeholder="empty"
            alt="Feature image"
          />
        </div>
      ))
    }
  </div>
);

const SectionWithImages = ({ children, images, caption, featuresClassName }: IProps) => (
  <div className={ styles.tabContent }>
    <div className={ cn(styles.featuresList, featuresClassName) }>
      { children }
    </div>
    <div className={ styles.screenshotWrp }>
      { images.length === 1 ? singleImage(images) : multipleImage(images) }
      <div className={ styles.caption }>{ caption }</div>
    </div>
  </div>
);

export default SectionWithImages;
